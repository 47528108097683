import React from 'react';
import TextBox from './TextBox';
import Button from './Button';
import RadioButtonGroup from './RadioButtonGroup';
import RadioButton from './RadioButton';
import { pickTextColorBasedOnBgColorSimple, getBrowserLanguage } from '../util';
import { translate } from '../../backend/locale';
import { PAYMENT_METHOD_CARD, PAYMENT_METHOD_MOBILE, PAYMENT_METHOD_MAESTRO } from '../constants';

export default function Form({
    primaryColor,
    departureDate,
    flightNumber,
    flightNumberPrefix,
    seatNumber,
    lastDigits,
    departureDateValid,
    flightNumberValid,
    seatNumberValid,
    lastDigitsValid,
    onDepartureDateChange,
    onFlightNumberChange,
    onSeatNumberChange,
    onLastDigitsChange,
    onDepartureDateBlur,
    onFlightNumberBlur,
    onSeatNumberBlur,
    onLastDigitsBlur,
    onPaymentMethodChange,
    selectedPaymentMethod,
    enablePaymentMethods,
    onSubmit,
    companyType,
  }) {
    const icon = primaryColor && pickTextColorBasedOnBgColorSimple(primaryColor, '#ffffff', '#434343') === '#ffffff' ? 'aircraft.png' : 'aircraft-dark.png';
    const showAirplaneIcon = companyType === 'air';
    const textBoxes = [
      <TextBox
        key={1}
        text={departureDate}
        label={translate('search_departure_date', getBrowserLanguage())}
        type={'date'}
        valid={departureDateValid}
        onBlur={onDepartureDateBlur}
        placeholder={(new Date()).toLocaleDateString('en-GB')}
        mandatory={true}
        onChange={onDepartureDateChange}/>,
      <TextBox
        key={2}
        text={lastDigits}
        label={translate('search_last_4_digits', getBrowserLanguage())}
        placeholder={'4460'}
        mandatory={![PAYMENT_METHOD_MOBILE, PAYMENT_METHOD_MAESTRO].includes(selectedPaymentMethod)}
        valid={lastDigitsValid}
        onBlur={onLastDigitsBlur}
        onChange={onLastDigitsChange}
        type='number'/>,
      <TextBox
        key={3}
        mandatory={[PAYMENT_METHOD_MOBILE, PAYMENT_METHOD_MAESTRO].includes(selectedPaymentMethod)}
        text={flightNumber}
        label={translate(`search_journey_number_${companyType}`, getBrowserLanguage())}
        placeholder={'8412'}
        maxLength={4}
        valid={flightNumberValid}
        onBlur={onFlightNumberBlur}
        pretext={flightNumberPrefix}
        onChange={onFlightNumberChange}
        type='number'/>,
    ];
    if ([PAYMENT_METHOD_MOBILE, PAYMENT_METHOD_MAESTRO].includes(selectedPaymentMethod)) {
      textBoxes.push(
        <TextBox
          key={4}
          mandatory={[PAYMENT_METHOD_MOBILE, PAYMENT_METHOD_MAESTRO].includes(selectedPaymentMethod)}
          text={seatNumber}
          label={translate('search_seat_number', getBrowserLanguage())}
          placeholder={'12A'}
          onBlur={onSeatNumberBlur}
          valid={seatNumberValid}
          maxLength={3}
          onEnter={onSubmit}
          onChange={onSeatNumberChange}/>
      );
      textBoxes.push(textBoxes.splice(1, 1)[0]);
    }
    return (
      <div>
        <div className='title'>{translate(`search_guide_${companyType}`, getBrowserLanguage())}</div>
          { enablePaymentMethods && <RadioButtonGroup title={translate('search_payment_method', getBrowserLanguage())}>
            <RadioButton
              style={window.innerWidth > 480 ? {width: '37%'} : {}}
              checked={selectedPaymentMethod === PAYMENT_METHOD_CARD}
              onChange={onPaymentMethodChange}
              id={PAYMENT_METHOD_CARD}
              label={translate(PAYMENT_METHOD_CARD, getBrowserLanguage())}
            />
            <RadioButton
              style={window.innerWidth > 480 ? {width: '31%'} : {}}
              checked={selectedPaymentMethod === PAYMENT_METHOD_MOBILE}
              onChange={onPaymentMethodChange}
              id={PAYMENT_METHOD_MOBILE}
              label={translate(PAYMENT_METHOD_MOBILE, getBrowserLanguage())}
            />
            <RadioButton
              style={window.innerWidth > 480 ? {width: '31%'} : {}}
              checked={selectedPaymentMethod === PAYMENT_METHOD_MAESTRO}
              onChange={onPaymentMethodChange}
              id={PAYMENT_METHOD_MAESTRO}
              label={translate(PAYMENT_METHOD_MAESTRO, getBrowserLanguage())}
            />
          </RadioButtonGroup>
          }
          {textBoxes}
          <div style={{ marginTop: 35, marginBottom: 25 }}><Button onClick={onSubmit} text={translate('btn_find_my_receipts', getBrowserLanguage())} icon={showAirplaneIcon && `/static/${icon}`}/></div>
      </div>
    );
  }