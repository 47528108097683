import React from 'react';
import ReactDOM from 'react-dom';
import Navigo from 'navigo';
import SearchForm from './SearchForm';
import SearchResult from './SearchResult';
import Loading from './Loading';
import Button from './Button';
import moment from 'moment';
import '../css/index.css';
import { getTheme, getReceipts } from '../service';
import { translate, translateTemplate } from '../../backend/locale';
import { lightenDarkenColor, pickTextColorBasedOnBgColorSimple, getBrowserLanguage, } from '../util';
import { PAYMENT_METHOD_CARD } from '../constants';

moment.locale(getBrowserLanguage());
const root = window.location.origin;
const useHash = true; // Defaults to: false
const hash = '#'; // Defaults to: '#'
const router = new Navigo(root, useHash, hash);

const initialState = {
  pageId: 'landing',
  isSplash: true,
  departureDate: moment().subtract(1, 'days').toDate(),
  flightNumber: '',
  seatNumber: '',
  lastDigits: '',
  name: '',
  logoUrl: '',
  primaryColor: null,
  secondaryColor: null,
  foundItems: null,
  flightNumberPrefix: '',
  departureDateValid: true,
  flightNumberValid: false,
  seatNumberValid: false,
  lastDigitsValid: false,
  departureDateUsed: false,
  flightNumberUsed: false,
  seatNumberUsed: false,
  lastDigitsUsed: false,
  companyEmail: null,
  selectedPaymentMethod: PAYMENT_METHOD_CARD,
  enablePaymentMethods: false,
  companyType: 'air',
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.onDepartureDateChange = this.onDepartureDateChange.bind(this);
    this.onFlightNumberChange = this.onFlightNumberChange.bind(this);
    this.onSeatNumberChange = this.onSeatNumberChange.bind(this);
    this.onLastDigitsChange = this.onLastDigitsChange.bind(this);
    this.onDepartureDateBlur = this.onDepartureDateBlur.bind(this);
    this.onFlightNumberBlur = this.onFlightNumberBlur.bind(this);
    this.onSeatNumberBlur = this.onSeatNumberBlur.bind(this);
    this.onLastDigitsBlur = this.onLastDigitsBlur.bind(this);
    this.onSearchFormSubmit = this.onSearchFormSubmit.bind(this);
    this.onPaymentMethodChange = this.onPaymentMethodChange.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  async onSearchFormSubmit(date) {
    router.navigate('/searchResult');
    const { departureDate, lastDigits, seatNumber, flightNumber, selectedPaymentMethod } = this.state;
    try {
      const foundItems = await getReceipts({
        departureDate,
        lastDigits,
        seatNumber,
        flightNumber: this.state.flightNumber ? this.state.flightNumberPrefix + this.state.flightNumber : '',
        selectedPaymentMethod,
      });
      this.setState({ foundItems: foundItems || [], flightNumber });
    } catch(error) {
      this.setState({ foundItems: [], flightNumber });
    }
  }

  onBack() {
    router.navigate('/');
  }

  onDepartureDateBlur() {
      this.setState({ departureDateUsed: true });
  }

  onFlightNumberBlur() {
      this.setState({ flightNumberUsed: true });
  }

  onSeatNumberBlur() {
      this.setState({ seatNumberUsed: true });
  }

  onLastDigitsBlur() {
      this.setState({ lastDigitsUsed: true });
  }

  onDepartureDateChange(date) {
      this.setState({ departureDate: date, departureDateValid: true });
  }

  onFlightNumberChange(event) {
    const value = event.target.value.substring(0, 4);
    const flightNumberValid = /\d{4}$/.test(value);
    this.setState({ flightNumber: value, flightNumberValid });
  }

  onSeatNumberChange(event) {
      const value = event.target.value.toUpperCase();
      const seatNumberValid = /\d{1,2}[ABCDEF]{1}$/g.test(value);
      this.setState({ seatNumber: value, seatNumberValid });
  }

  onLastDigitsChange(event) {
    const value = event.target.value.substring(0, 4);
    const lastDigitsValid = /\d{4}$/.test(value);
    this.setState({ lastDigits: value, lastDigitsValid });
  }

  onPaymentMethodChange(selectedPaymentMethod) {
    this.setState({ selectedPaymentMethod });
  }

  startSplash() {
    this.timeout && clearTimeout(this.timeout);
    this.setState({ isSplash: true });
    this.timeout = setTimeout(() => {
        this.setState({ isSplash: false });
    }, 100);
  }

  componentDidMount() {
    router.on({
      '*': async (params = {}, query = {}) => {
        const {
          name,
          logoUrl,
          primaryColor,
          secondaryColor,
          favicon,
          isRoundedCorners,
          flightNumberPrefix,
          companyEmail,
          enablePaymentMethods,
          companyType,
        } = await getTheme();
        generateStyle({ primaryColor, secondaryColor, isRoundedCorners });
        updateFavicon(favicon);
        this.setState({ pageId: 'landing', logoUrl, name, primaryColor, foundItems: null, flightNumberPrefix, companyEmail, enablePaymentMethods, companyType });
        this.startSplash();
      },
      '/searchResult': (params = {}, query = {}) => {
        if (!this.state.name) {
          router.navigate('/');
          return;
        }
        this.setState({ pageId: 'searchResult' });
        this.startSplash();
      },
    }).resolve();
  }

  render() {
    const {
      pageId,
      isSplash,
      departureDate,
      flightNumber,
      seatNumber,
      lastDigits,
      departureDateValid,
      flightNumberValid,
      seatNumberValid,
      lastDigitsValid,
      departureDateUsed,
      flightNumberUsed,
      seatNumberUsed,
      lastDigitsUsed,
      logoUrl,
      name,
      primaryColor,
      foundItems,
      flightNumberPrefix,
      companyEmail,
      selectedPaymentMethod,
      enablePaymentMethods,
      companyType,
    } = this.state;
    const {
      onDepartureDateChange,
      onFlightNumberChange,
      onSeatNumberChange,
      onLastDigitsChange,
      onDepartureDateBlur,
      onFlightNumberBlur,
      onSeatNumberBlur,
      onLastDigitsBlur,
    } = this;
    let page;
    let backButton;
    if (pageId === 'landing') {
      page = SearchForm({
        primaryColor,
        departureDate,
        flightNumber,
        seatNumber,
        lastDigits,
        departureDateValid: departureDateValid || !departureDateUsed,
        flightNumberValid: flightNumberValid || !flightNumberUsed,
        seatNumberValid: seatNumberValid || !seatNumberUsed,
        lastDigitsValid: lastDigitsValid || !lastDigitsUsed,
        onDepartureDateChange,
        onFlightNumberChange,
        onSeatNumberChange,
        onLastDigitsChange,
        onDepartureDateBlur,
        onFlightNumberBlur,
        onSeatNumberBlur,
        onLastDigitsBlur,
        onSubmit: this.onSearchFormSubmit,
        flightNumberPrefix,
        onPaymentMethodChange: this.onPaymentMethodChange,
        selectedPaymentMethod,
        enablePaymentMethods,
        companyType,
      });
    } else if (pageId === 'searchResult' && Array.isArray(foundItems)) {
      page = SearchResult({
        foundItems,
        companyEmail,
        companyType,
        onBack: this.onBack,
      });
      backButton = <Button onClick={this.onBack} text={translate('btn_go_back', getBrowserLanguage())}/>;
    } else if (pageId === 'searchResult') {
      page = Loading(primaryColor);
    }
    return (
      <div className='centered' style={{ opacity: isSplash ? 0 : 1, transition: 'opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)' }}>
        <div className={`content ${pageId === 'searchResult' && Array.isArray(foundItems) && foundItems.length ? 'large' : ''}`}>
          <div style={{
              width: 260,
              display: 'inline-block',
          }}>
            <div style={{
              width: 260,
              height: 115,
              backgroundImage: `url('${logoUrl}')`,
              backgroundPosition: `center`,
              backgroundSize: `contain`,
              backgroundRepeat: `no-repeat`,
            }}/>
          </div>
            {page}
            {backButton}
          <div className='footer'>
            <div><i>{translate(`disclaimer_${companyType}`, getBrowserLanguage())}</i></div>
            <div style={{ marginTop: 5 }}>{translateTemplate('footer', [name], getBrowserLanguage())}</div>
          </div>
        </div>
      </div>
    );
  }
}

function generateStyle({ primaryColor, secondaryColor, isRoundedCorners }) {
  if (!primaryColor) {
    return;
  }
  var css = `
    body {
      background-image: linear-gradient(30deg, ${primaryColor}, ${primaryColor});
    }
    input:focus {
      /* box-shadow: #f28d1b 0px 0px 0px 2px; */
      box-shadow: ${primaryColor} 0px 0px 0px 2px;
    }
    button {
      background: ${primaryColor};
      color: ${pickTextColorBasedOnBgColorSimple(primaryColor, '#ffffff', '#434343')};
      border-radius: ${ isRoundedCorners ? '5px' : '0' };
    }
    button:hover {
      background: ${lightenDarkenColor(primaryColor, -20)};
    }
    button:active {
      background: ${lightenDarkenColor(primaryColor, -40)};
    }
    .content {
      border-radius: ${ isRoundedCorners ? '10px' : '0' };
    }
    input {
      border-radius: ${ isRoundedCorners ? '5px' : '0' };
    }
    .radio-button-group {
      border-radius: ${ isRoundedCorners ? '5px' : '0' };
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: ${primaryColor};
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
  `;
  var style = document.createElement('style');
  
  if (style.styleSheet) {
      style.styleSheet.cssText = css;
  } else {
      style.appendChild(document.createTextNode(css));
  }
  
  document.getElementsByTagName('head')[0].appendChild(style);
}

function updateFavicon(url) {
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/png';
  link.rel = 'shortcut icon';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
}

ReactDOM.render(<App />, document.getElementById('root'));
